class CategoriaHandle {

    formToJso(formData) {
        return {
            id: formData.id,
            titulo: formData.titulo,
            status: formData.status,
        };
    }

}

export default new CategoriaHandle();