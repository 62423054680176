<template>
  <div>
    <div class="row">
      <caption>
        <div class="d-grid mb-4" v-if="btnNew">
          <button
            class="btn btn-success bg-success bg-gradient shadow-sm col-sm-6 col-md-4 col-lg-4"
            type="button"
            @click="actionNew()"
          >
            {{ $t("newone") }} <i class="bi bi-plus-circle"></i>
          </button>
        </div>

        <hr class="dropdown-divider" />

        <div class="input-group">
          <input
            type="text"
            class="form-control shadow-sm"
            id="searchWord"
            v-model="searchWord"
            :placeholder="$t('searchintabledata')"
          />
          <span class="input-group-text bg-light text-muted" id="basic-addon2"
            ><i class="bi bi-search"></i
          ></span>
        </div>
      </caption>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-hover align-middle">
        <thead class="table-light table-bordered">
          <tr>
            <th scope="col" v-for="itemLabel in labels" :key="itemLabel">
              {{ $t(itemLabel.key) }}
            </th>
            <th class="text-end">
              {{ $t("action") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="itemData in listComp" :key="itemData.id">
            <td scope="row" v-for="itemLabel in labels" :key="itemLabel">
              <span 
                v-if="itemLabel.type == 'boolean' && itemData[itemLabel.key] == '1'"
                >{{ itemLabel.options.true }}</span
              >
              <span
                v-if="itemLabel.type == 'boolean' && itemData[itemLabel.key] == '0'"
                >{{ itemLabel.options.false }}</span
              >
              <span v-if="itemLabel.type == 'number'"> {{
                itemData[itemLabel.key]
              }}</span>
              <span v-if="itemLabel.type == 'text'">{{
                itemData[itemLabel.key]
              }}</span>
              <span v-if="itemLabel.type == 'date'">{{
                dataHandler(itemData[itemLabel.key])
              }}</span>
              <img
                v-if="itemLabel.type == 'image' && itemLabel.field"
                :src="itemLabel.path+itemData[itemLabel.key]+'/'+itemLabel.field"
                class="img-fluid img-thumb-100"
              />
              <img
                v-if="itemLabel.type == 'image' && !itemLabel.field"
                :src="itemLabel.path+itemData[itemLabel.key]"
                class="img-fluid img-thumb-100"
              />
              <span v-if="itemLabel.type == 'translate'">
                {{ $t(itemData[itemLabel.key]?itemLabel.options.keytrue:itemLabel.options.keyfalse) }}
                <!-- {{ $t(itemData[itemLabel.key]) }} -->
              </span>
              <span v-if="itemLabel.type == 'array'">
                {{ itemLabel.array[itemData[itemLabel.key]] }}
              </span>
            </td>
            <td class="text-end">
              <button
                type="button"
                v-for="action in actions"
                :key="action"
                :class="action.class"
                @click="action.action(itemData)"
              >
                <i v-if="action.ico" :class="action.ico"></i>
                {{ $t(action.name) }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center mb-5">
        <li class="page-item mb-2">
          <button
            class="btn btn-sm btn-light bg-light shadow-sm"
            type="button"
            @click="showMore()"
          >
            {{ $t("showmore") }} <i class="bi bi-arrow-bar-down"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: [
    "labels",
    "fieldsFilter",
    "list",
    "pageSize",
    "actionNew",
    "actionEdit",
    "actionDelete",
    "btnNew",
    "actions",
  ],
  data: () => ({
    searchWord: "",
    actualReg: 0,
  }),
  mounted() {
    this.showMore();
  },
  methods: {
    showMore: function () {
      this.actualReg += this.pageSize;
    },
    dataHandler: function (value) {
      let retDate;
      try {
        let options = { year: "numeric", month: "2-digit", day: "2-digit" };
        retDate = new Date(value).toLocaleDateString("pt-BR", options);
      } catch (error) {
        retDate = "Range Error";
      }
      return retDate;
    },
  },
  computed: {
    listComp: {
      get() {
        if (this.fieldsFilter)
          return this.list
            .filter((el) => {
              let retStatus = false;
              this.fieldsFilter.forEach((field) => {
                //console.log(el[field]);
                let valueField = "";
                if(!el[field]){
                    valueField = "";
                }else{
                  valueField = el[field];
                }
                if (
                  valueField
                    .toLowerCase()
                    .indexOf(this.searchWord.toLowerCase()) > -1
                ) {
                  retStatus = true;
                }
              });
              return retStatus;
            })
            .slice(0, this.actualReg);
        else
          return this.list.slice(0, this.actualReg);
      },
    },
  },
};
</script>

<style>
</style>