import axios from 'axios';
import authHeader from './auth-header';

import { URL_API } from './Commons';

class CategoriaService {

  async getAll() {
    return await axios.get(URL_API + 'categorias', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'categoria/' + id, { headers: authHeader() });
  }

  insert(categoria) {
    return axios.post(URL_API + 'categoria', categoria, { headers: authHeader() });
  }

  update(id, categoria) {
    return axios.put(URL_API + 'categoria/' + id, categoria, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'categoria/' + id, { headers: authHeader() });
  }

}

export default new CategoriaService();