<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <form @submit="sendEdit">
      <div class="row">
        <div class="col-12 mb-3">
          <label for="titulo" class="form-label">{{ $t("title") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="formEdit.titulo"
            aria-describedby="titulo"
          />
        </div>
        <div class="mb-3" v-if="!deleteMode">
          <label for="status_input" class="form-label">{{
            $t("status")
          }}</label>
          <select
            class="form-select"
            @change="formEdit.status = $event.target.value"
            v-model="formEdit.status"
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { URL_BASE } from "../../../../../services/Commons";
import CategoriaService from "../../../../../services/categoria.service";
import CategoriaHandle from "../../../../../services/handle/categoria.handle";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    loading: false,
    moment: null,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.urlImg = URL_BASE + `file/categoria/byField/${this.dataItem.id}/`;
    this.formEdit = this.dataItem;
    //console.log(this.formEdit);
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      //
      if (this.deleteMode) {
        CategoriaService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({ msg: "O item foi excluído com Sucesso.", style: "success" });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      CategoriaService.update(
        this.formEdit.id,
        CategoriaHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          //console.log(result);
          result;
          this.loading = false;
          this.actionSend({ msg: "O item foi alterado com Sucesso.", style: "success" });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
    sendFile: function (field) {
      this.loading = true;
      if (this.$refs[field + "update"].files[0]) {
        let formData = new FormData();
        formData.append("file", this.$refs[field + "update"].files[0]);
        formData.append("fieldFile", field);
        CategoriaService.sendFile(this.formEdit.id, formData).then(
          (result) => {
            console.log(result);
            console.log(this.formEdit);
            this.formEdit[field] = result.data;
            this.loading = false;
            this.ActionSendFile();
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }
    },
  },
};
</script>

<style>
</style>